import { createSlice } from '@reduxjs/toolkit';
import { questionShopApi } from 'api';
import { AppThunk } from 'store';
import { Question } from 'types/question';

interface QuestionState {
	questions: Question[];
	isLoading: boolean;
	error: boolean;
}

const initialState: QuestionState = {
	questions: [],
	isLoading: false,
	error: false,
};

const slice = createSlice({
	name: 'shopQuestion',
	initialState: initialState,
	reducers: {
		/* Set Status */
		setLoading: (state, action) => {
			state.isLoading = action.payload;
		},

		hasError: (state, action) => {
			state.isLoading = false;
			state.error = action.payload;
		},

		/* Get All Questions */
		list: (state, action) => {
			state.questions = action.payload;
			state.isLoading = false;
		},

		/* Create Question */
		create: (state, action) => {
			state.questions.unshift(action.payload);
			state.isLoading = false;
		},

		/* Update Question */
		update: (state, action) => {
			const question = action.payload;
			const index = state.questions.findIndex(x => x.id === question.id);

			if (index > -1) {
				state.questions[index] = question;
			} else {
				state.error = true;
			}
			state.isLoading = false;
		},

		/* Remove Question */
		remove: (state, action) => {
			const index = state.questions.findIndex(x => x.id === action.payload);
			if (index > -1) {
				state.questions.splice(index, 1);
			}

			state.isLoading = false;
		},
	},
});

/**
 * List Question
 * @param workspaceId
 * @returns
 */
export const listShopQuestions =
	(): AppThunk =>
	async (dispatch): Promise<void> => {
		const response: any = await questionShopApi.getAll('product');
		dispatch(slice.actions.list(response));
	};

/**
 * Create Question
 *
 * @param request
 * @returns
 */
export const createQuestion =
	(request: any): AppThunk =>
	async (dispatch): Promise<void> => {
		const response: any = await questionShopApi.create(request, 'product');
		dispatch(slice.actions.create(response));
		return response;
	};

/**
 * Update Question
 * @param id
 * @param request
 * @returns
 */
export const updateQuestion =
	(questionId: string, request: any): AppThunk =>
	async (dispatch): Promise<void> => {
		const response: any = await questionShopApi.update(questionId, request, 'product');
		dispatch(slice.actions.update(response));
		return response;
	};

/**
 * Delete Question
 * @param id
 *
 * @returns
 */
export const deleteQuestion =
	(questionId: string): AppThunk =>
	async (dispatch): Promise<void> => {
		await questionShopApi.delete(questionId, 'product');
		dispatch(slice.actions.remove(questionId));
	};

export const { reducer } = slice;
export default slice;
