import { combineReducers } from '@reduxjs/toolkit';
import { reducer as settingsReducer } from 'layout/slice';
import { reducer as authReducer } from 'features/Authentication/slice';

import { reducer as languageReducer } from 'slices/language';
import { reducer as fieldLanguageReducer } from 'slices/fieldLanguage';
import { reducer as questionShopReducer } from 'slices/questionShop';
import { reducer as questionShopBetaReducer } from 'slices/questionShopBeta';

const rootReducer = combineReducers({
	settings: settingsReducer,
	auth: authReducer,
	fieldLanguage: fieldLanguageReducer,
	language: languageReducer,
	questionShop: questionShopReducer,
	questionShopBeta: questionShopBetaReducer,
});

export default rootReducer;
