import axiosClient from 'lib/axios';
import { InjectionTarget, LanguageType } from 'types/language';

const api = {
	// Language
	createLanguages: (request: any) => {
		return axiosClient.post(`/language`, request);
	},
	updateLanguages: (id: string, request: any) => {
		return axiosClient.patch(`/language/${id}`, request);
	},
	deleteLanguage: (id: string) => {
		return axiosClient.delete(`/language/${id}`);
	},

	getAllLanguages: () => {
		return axiosClient.get(`/language`);
	},

	// field Language

	getAllFieldLanguage: (id: string, limit: number, page: number = 1, type: LanguageType) => {
		return axiosClient.get(`/field-language`, {
			params: { language: id, page: page, limit: limit, type },
		});
	},
	createFieldLanguage: (fieldConstant: string, fieldTranslated: any, type: LanguageType) => {
		return axiosClient.post(`/field-language`, {
			fieldConstant: fieldConstant,
			fieldTranslated: fieldTranslated,
			type,
		});
	},
	updateFieldLanguage: (fieldId: string, fieldConstant: string, fieldTranslated: any, type: LanguageType) => {
		return axiosClient.patch(`/field-language/${fieldId}`, {
			fieldConstant: fieldConstant,
			fieldTranslated: fieldTranslated,
			type,
		});
	},
	deleteFieldLanguage: (fieldId: string) => {
		return axiosClient.delete(`/field-language/${fieldId}`);
	},

	// import CSV
	importCsv: (request: FormData, type: LanguageType) => {
		return axiosClient.post(`/field-language/upload-csv/${type}`, request, {
			headers: {
				'Content-Type': 'multipart/form-data',
			},
		});
	},

	inject: (injectionTarget: InjectionTarget, type: LanguageType) => {
		return axiosClient.post(`/field-language/${injectionTarget}`, { type });
	},
};

export default api;
