import LanguageIcon from 'assets/icons/Language';
import QuestionAnswerIcon from '@material-ui/icons/QuestionAnswer';

export const menus = [
	{
		title: 'Management',
		items: [
			{
				title: 'Languages',
				path: '/language',
				icon: <LanguageIcon fontSize="small" />,
			},
			{
				title: 'Questions for production',
				path: `/question/product`,
				icon: <QuestionAnswerIcon fontSize="small" />,
			},
			{
				title: 'Questions for beta',
				path: `/question/beta`,
				icon: <QuestionAnswerIcon fontSize="small" />,
			},
		],
	},
];
