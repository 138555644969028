import axiosClient from 'lib/axios';
import { QuestionStage } from 'types/question';

const prefixUrl = 'question-templates';

const api = {
	/**
	 * Get all Questions
	 */
	getAll: (stage: QuestionStage) => {
		return axiosClient.get(`${stage}/shop/${prefixUrl}/`);
	},

	/* Create Question */
	create: (request: any, stage: QuestionStage) => {
		return axiosClient.post(`${stage}/shop/${prefixUrl}`, request);
	},

	uploadQuestonShopImage: (request: FormData, stage: QuestionStage) => {
		return axiosClient.post(`${stage}/shop/${prefixUrl}/image`, request);
	},
	uploadQuestonShopVideo: (request: FormData, stage: QuestionStage) => {
		return axiosClient.post(`${stage}/shop/${prefixUrl}/video`, request);
	},

	/* Update Question */
	update: (questionId: string, request: any, stage: QuestionStage) => {
		return axiosClient.patch(`${stage}/shop/${prefixUrl}/${questionId}`, request);
	},

	/* Delete Question */
	delete: (questionId: string, stage: QuestionStage) => {
		return axiosClient.delete(`${stage}/shop/${prefixUrl}/${questionId}`);
	},

	//Delete video
	deleteQuestionShopVideo: (filename: string, stage: QuestionStage) => {
		return axiosClient.delete(`${stage}/shop/${prefixUrl}/video`, { params: { filename } });
	},
	//Delete video
	deleteQuestionShopVideoCloud: (filename: string, stage: QuestionStage) => {
		return axiosClient.delete(`${stage}/shop/${prefixUrl}/video-cloud`, { params: { filename } });
	},
	//Delete video
	deleteQuestionShopImage: (filename: string, stage: QuestionStage) => {
		return axiosClient.delete(`${stage}/shop/${prefixUrl}/image`, { params: { filename } });
	},
};

export default api;
