import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import adminApi from 'api/adminAPI';
import { languageCodeFull } from 'assets/lang/languageCode';
import { AppThunk } from 'store';
import { Language } from 'types/language';
import objFromArray from 'utils/objFromArray';

interface LanguageState {
	isLoading: boolean;
	error: boolean;
	languages: {
		byId: Record<string, Language>;
		allIds: string[];
	};
}

const initialState: LanguageState = {
	isLoading: false,
	error: false,
	languages: {
		byId: {},
		allIds: [],
	},
};

const slice = createSlice({
	name: 'language',
	initialState,
	reducers: {
		getLanguages: (state: LanguageState, action: PayloadAction<Language[]>) => {
			const languages: Language[] = action.payload;

			state.languages.byId = objFromArray(languages);
			state.languages.allIds = Object.keys(state.languages.byId);
		},
		createLanguage: (state: LanguageState, action: PayloadAction<Language>) => {
			const language = action.payload;
			state.languages.byId[language.id] = language;
			state.languages.allIds.push(language.id);
		},
		updateLanguage: (state: LanguageState, action: PayloadAction<Language>) => {
			const language = action.payload;
			state.languages.byId[language.id] = language;
		},

		deleteLanguage: (state: LanguageState, action: PayloadAction<string>) => {
			const languageId = action.payload;

			delete state.languages.byId[languageId];
			state.languages.allIds.filter(_languageId => _languageId !== languageId);
		},
	},
});

export const createLanguages =
	(request: any): AppThunk =>
	async (dispatch): Promise<string[]> => {
		const response: any = await adminApi.createLanguages(request);
		dispatch(slice.actions.createLanguage(response));
		return response;
	};

export const updateLanguage =
	(id: string, request: any): AppThunk =>
	async (dispatch): Promise<void> => {
		const response: any = await adminApi.updateLanguages(id, request);
		dispatch(slice.actions.updateLanguage(response));
	};

export const deleteLanguage =
	(id: string): AppThunk =>
	async (dispatch): Promise<void> => {
		await adminApi.deleteLanguage(id);
		dispatch(slice.actions.deleteLanguage(id));
	};

export const { reducer, actions } = slice;
