import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { AppThunk } from 'store';
import { FieldLanguage } from 'types/field-language';
import adminApi from 'api/adminAPI';
import { LanguageType } from 'types/language';

interface FieldLanguageState {
	field: FieldLanguage[];
	isLoading: boolean;
	error: boolean;
}

const initialState: FieldLanguageState = {
	field: [],
	isLoading: false,
	error: false,
};

const slice = createSlice({
	name: 'interview',
	initialState,
	reducers: {
		setLoading: (state: FieldLanguageState, action: PayloadAction<boolean>) => {
			state.isLoading = action.payload;
		},
		hasError: (state, action) => {
			state.isLoading = false;
			state.error = action.payload;
		},

		gets: (state: FieldLanguageState, action: PayloadAction<FieldLanguage[]>) => {
			state.field = action.payload;
			state.isLoading = false;
		},

		create: (state: FieldLanguageState, action: PayloadAction<FieldLanguage>) => {
			state.field.unshift(action.payload);
			state.isLoading = false;
		},

		update: (state, action) => {
			const field = action.payload;
			const index = state.field.findIndex(x => x.id === field.id);

			if (index > -1) {
				state.field[index] = field;
			} else {
				state.error = true;
			}
			state.isLoading = false;
		},
		remove: (state, action) => {
			const index = state.field.findIndex(x => x.id === action.payload);
			if (index > -1) {
				state.field.splice(index, 1);
			}

			state.isLoading = false;
		},
	},
});

export const setLoading =
	(isLoading: boolean): AppThunk =>
	(dispatch): void => {
		dispatch(slice.actions.setLoading(isLoading));
	};

/**
 * Get All Fields
 * @param languageId
 * @param page
 * @param limit
 * @returns
 */
export const getAllFields =
	(languageId: string, page: number = 1, limit: number = 10, type: LanguageType): AppThunk =>
	async (dispatch): Promise<any> => {
		dispatch(slice.actions.setLoading(true));
		const response: any = await adminApi.getAllFieldLanguage(languageId, limit, page, type);
		dispatch(slice.actions.gets(response?.fieldLanguages));
		dispatch(slice.actions.setLoading(false));
		return response;
	};

/**
 * Create Field
 *
 * @param fieldConstant
 * @param fieldTranslated
 * @returns
 */
export const createField =
	(fieldConstant: string, fieldTranslated: any, type: LanguageType): AppThunk =>
	async (dispatch): Promise<any> => {
		const response: any = await adminApi.createFieldLanguage(fieldConstant, fieldTranslated, type);
		dispatch(slice.actions.create(response));
		return response;
	};

/**
 * Update Field
 * @param fieldId
 *
 * @param fieldConstant
 * @param fieldTranslated
 * @returns
 */
export const updateField =
	(fieldId: string, fieldConstant: string, fieldTranslated: any, type: LanguageType): AppThunk =>
	async (dispatch): Promise<any> => {
		const response: any = await adminApi.updateFieldLanguage(fieldId, fieldConstant, fieldTranslated, type);
		dispatch(slice.actions.update(response));
		return response;
	};

/**
 * Delete Field
 * @param fieldId
 *
 * @returns
 */
export const deleteField =
	(fieldId: string): AppThunk =>
	async (dispatch): Promise<any> => {
		const response: any = await adminApi.deleteFieldLanguage(fieldId);
		dispatch(slice.actions.remove(fieldId));
		return response;
	};

export const { reducer } = slice;
export default slice;
